import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, List,
} from '../../components/opComponents';

const TendiniteDeDeQuervain = () => (
  <Layout
    title="Tendinite de De Quervain : Diagnostic et traitement"
    description="La Tendinite de De Quervain est une inflammation des tendons court extenseur et long abducteur du pouce en regard du bord externe du poignet."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-de-la-main" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations de la main</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Tendinite de De Quervain" />
        <MainPart title="Qu'est-ce qu'une tendinite de De Quervain ?">
          <Typography>
            La Tendinite de De Quervain
            {' '}
            <Typography variant="span">
              est une inflammation des tendons (ténosynovite) court extenseur
              et long abducteur du pouce en regard du bord externe du poignet.
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les tendons qui forment le premier compartiment des extenseurs ?">
          <Typography>
            Ces deux tendons cheminent dans le même tunnel fibreux au contact
            du radius et forment le premier compartiment des extenseurs (Figure 1).
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure1-tendinitededequervain.jpg"
            caption="Figure 1. Ténosynovite du court extenseur et du long abducteur du pouce au niveau du poignet"
            currentOpTitle="Tendinite de De Quervain"
          />
        </MainPart>
        <MainPart title="Quelles sont les causes d'une tendinite de De Quervain ?">
          <Typography>
            L&apos;irritation survient à l&apos;occasion d&apos;un changement d&apos;activité,
            d&apos;un choc ou d&apos;une utilisation
            inhabituelle de la main. Une fois installée, la ténosynovite
            s&apos;auto-entretient par les
            frottements des tendons qui augmentent de volume dans une gaine inextensible.
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les symptômes d'une tendinite de De Quervain ?">
          <Typography>
            Le premier symptôme est la douleur au bord externe du poignet. Un gonflement
            peut être palpable à ce niveau traduisant l&apos;épaississement du
            tunnel et du tissu synovial qui recouvre le tendon.
            {' '}
            <Typography variant="span" weight="bold">Le test de Finkelstein</Typography>
            {' '}
            est pratiquement toujours positif (Figure 2). On peut parfois
            observer des fourmillements du dos du pouce et/ou de la main.
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure2-tendinitededequervain.jpg"
            caption="Figure 2. Test de Finkelstein: la flexion du pouce et l&apos;inclinaison ulnaire du poignet reproduit la douleur"
            currentOpTitle="Tendinite de De Quervain"
          />
        </MainPart>
        <MainPart title="Quels sont les examens qui confirment une tendinite de De Quervain ?">
          <Typography weight="bold">
            Le diagnostic de la tendinite de De Quervain est clinique.
            {' '}
            <Typography variant="span">
              Une éventuelle échographie peut conforter le diagnostic. La présence,
              sur les clichés radiographiques, d&apos;une spicule osseuse au niveau du
              radius est un facteur prédictif d&apos;échec du traitement médical.
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les traitements médicaux et chirurgicaux de la tendinite de De Quervain ?">
          <Typography weight="bold">
            Médical :
            {' '}
            <Typography variant="span">
              le port d&apos;une attelle nocturne pour une durée d&apos;un mois (Figure 3),
              associé à une infiltration locale de cortisone peut être envisagé
              dans les formes débutantes. En cas de forme sévère ou d&apos;échec du
              traitement médical, une prise en charge chirurgicale est conseillée.
            </Typography>
          </Typography>
          <Figure
            maxWidth="max-w-[200px]"
            imageName="figure3-tendinitededequervain.jpg"
            caption="Figure 3. Attelle de repos à mettre la nuit en cas de tendinite débutante"
            currentOpTitle="Tendinite de De Quervain"
          />
          <Typography weight="bold">
            Médical :
            {' '}
            <Typography variant="span">
              le port d&apos;une attelle nocturne pour une durée d&apos;un mois (Figure 3),
              associé à une infiltration locale de cortisone peut être envisagé
              dans les formes débutantes. En cas de forme sévère ou d&apos;échec du
              traitement médical, une prise en charge chirurgicale est conseillée.
            </Typography>
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure4-tendinitededequervain.jpg"
            caption="Figure 4. Incision cutanée sur le bord externe du poignet"
            currentOpTitle="Tendinite de De Quervain"
          />
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure5-tendinitededequervain.jpg"
            caption="Figure 5. Libération du tunnel fibreux (sur la pince) et mise en évidence des tendons"
            currentOpTitle="Tendinite de De Quervain"
          />
          <Typography>
            Les tendons sont ensuite libérés et l&apos;inflammation
            des tendons est retirée (Figure 6).
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure6-tendinitededequervain.jpg"
            caption="Figure 6. Libération des tendons (sur la pince) qui ne présentent plus d&apos;inflammation"
            currentOpTitle="Tendinite de De Quervain"
          />
        </MainPart>
        <MainPart title="Quelles sont les suites  post-opératoires après une opération chirurgicale pour une tendinite de De Quervain ?">
          <Typography>
            Les résultats sont habituellement bons avec une amélioration
            progressive sur plusieurs semaines.
            {' '}
            <Typography variant="span" weight="bold">
              Un petit pansement est réalisé
            </Typography>
            {' '}
            tous les 2-3 jours pendant 14 jours. Dans la majorité des cas,
            aucune immobilisation n&apos;est nécessaire et la main peut être utilisée
            dès le lendemain, sans forcer. La rééducation post-opératoire
            n&apos;est pas forcement nécessaire.
          </Typography>
          <Typography>
            La reprise de la conduite est possible à partir du 7ème jour.
          </Typography>
          <Typography>
            La reprise d&apos;une activité sportive ou manuelle intensive
            est possible dès la fin du premier mois post-opératoire.
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les complications possibles après une opération chirurgicale pour une tendinite de De Quervain ?">
          <Typography>
            Il n&apos;existe pas d&apos;intervention chirurgicale sans risque de complications
            secondaires. Les complications peuvent être classées en deux catégories :
          </Typography>
          <Typography size="mdx" weight="bold">Complications non spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Infection du site opératoire pouvant nécessiter une
                intervention de nettoyage associée à la prise d&apos;antibiotiques
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hématome pouvant nécessiter une évacuation en cas
                de menace cutanée ou compression nerveuse
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Algodystrophie. Son apparition est indépendante du type de chirurgie.
                Elle évolue en deux phases : phase chaude (main gonflée, douloureuse
                avec transpiration) puis froide (prédominance de la raideur). L&apos;évolution
                est le plus souvent longue (12-18 mois) et des séquelles sont
                possibles (douleur et/ou raideur des articulations des
                doigts et/ou poignet et/ou épaule)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Accidents liés à l&apos;anesthésie
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Complications spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Cicatrice : elle peut rester sensible pendant plusieurs semaines. L&apos;évolution
                est le plus souvent favorable après massage et décollement de la cicatrice.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Fourmillements au dos de la main et du pouce. Elles sont en rapport
                avec une irritation des petites branches nerveuses sensitives et
                sont le plus souvent passagères.
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Douleurs persistantes : liées à la persistance de la synovite.
                Elles disparaissent le plus souvent spontanément après plusieurs mois.
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="mains" />
  </Layout>
);

export default TendiniteDeDeQuervain;
